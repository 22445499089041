import * as React from "react";

const Card = (props) => {
  return (
    <>
      <div
        onClick={() =>
          props.setAboutModal({
            title: props.cardTitle,
            content: props.cardExtra,
          })
        }
        data-bs-toggle={props.cardExtra ? "modal" : ""}
        data-bs-target={props.cardExtra ? "#aboutModal" : ""}
        className={`col ${props.cardExtra ? "pointer-mouse" : ""}`}
      >
        <div className="card h-100">
          <div className="card-body">
            <h5 className="card-title">{props.cardTitle}</h5>
            <p className="card-text cool-link">{props.cardText}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
